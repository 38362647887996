import { Grid, Container, SimpleGrid, ScrollArea, Box } from "@mantine/core";
import { useContext } from "react";
import FilePreview from "./preview/FilePreview";
import WebpagePreview from "./preview/WebpagePreview";
import FolderDataList from "./data/FolderDataList";
import FolderSearch from "./search/FolderSearch";
import WebSearch from "./search/WebSearch";
import { MainContext } from "./contexts/MainContext";
import WebDataList from "./data/WebDataList";
import { Authentication } from "./authentication/Authentication";
import { useAuth } from "./contexts/AuthContext";
import { IconLogout } from "@tabler/icons-react";
import { doSignOut } from "./firebase/auth";

const Home = () => {
  const { userLoggedIn } = useAuth();
  const { dataType, searchType } = useContext(MainContext);

  return (
    <Box>
      {userLoggedIn && (
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div></div>
          <IconLogout
            style={{
              cursor: "pointer",
              marginTop: "1rem",
              marginRight: "1rem",
            }}
            onClick={doSignOut}
          />
        </Box>
      )}
      <Container size="98vw" my="2vh">
        {!userLoggedIn ? (
          <Authentication />
        ) : (
          <Grid>
            <Grid.Col span={2}>
              <ScrollArea
                style={{
                  height: "90vh",
                }}
                scrollbarSize={0}
              >
                <SimpleGrid cols={1} spacing={0}>
                  {searchType === "folder" ? (
                    <FolderDataList />
                  ) : (
                    <WebDataList />
                  )}
                </SimpleGrid>
              </ScrollArea>
            </Grid.Col>
            <Grid.Col span={5}>
              {searchType === "folder" && dataType === "file" ? (
                <FilePreview />
              ) : (
                <WebpagePreview />
              )}
            </Grid.Col>
            <Grid.Col span={5}>
              {searchType === "folder" ? <FolderSearch /> : <WebSearch />}
            </Grid.Col>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default Home;
